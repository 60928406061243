<template>
  <v-main>
    <div style="padding: 34px;">
      <div class="d-flex justify-end" style="margin-bottom: 18px" v-if="checkPermission('bitu-shift-management_create')">
        <v-btn outlined color="#0075FF" @click="handleCreateSchedule">
          <v-icon left>
            mdi-plus
          </v-icon>
          Tạo ca
          </v-btn>
      </div>
      <div>
        <ScheduleManagementTable :schedules="schedules" @editSchedule="handleCreateSchedule" @deleteSchedule="handleDeleteSchedule"></ScheduleManagementTable>
      </div>
      <ScheduleDialog :mode="mode" :schedule="schedule" :isShow="isShowScheduleDialog" @close="closeScheduleDialog" @refreshData="getListSchedule"></ScheduleDialog>
    </div>
  </v-main>
</template>

<script>
import ScheduleManagementTable from "@/components/Schedule/ScheduleManagementTable";
import ScheduleDialog from "../Schedule/ScheduleDialog.vue"
import {ScheduleService} from "@/repositories/ScheduleService";
import _ from "lodash"
let scheduleService = new ScheduleService
export default {
  name: "ScheduleManagement",
  components: {
    ScheduleManagementTable,
    ScheduleDialog,
  },
  data() {
    return {
      isShowScheduleDialog: false,
      schedule: {
        name: '',
        startTime: '',
        endTime: '',
        autoEndTime: '',
      },
      schedules: [],
      mode: 'create',
    }
  },
  created() {
    this.getListSchedule();
  },
  methods: {
    async handleDeleteSchedule(id){
      let result = await scheduleService.deleteSchedule(id)
      if (result.message == 'Success') {
        this.$swal.fire({
          title: 'Xóa ca thành công !',
          icon: 'success',
          width: 450,
        })
      } else {
        this.$swal.fire({
          title: 'Xóa ca thất bại !',
          icon: 'error',
          text: result.message,
          width: 450,
        })
      }
      this.getListSchedule();
    },
    closeScheduleDialog() {
      this.isShowScheduleDialog = false;
    },
    handleCreateSchedule(item) {
      if (item._id) {
        this.mode = 'edit'
        this.schedule = _.clone(item);
      } else {
        this.mode = 'create'
        this.schedule = {
          name: '',
          startTime: '',
          endTime: '',
          autoEndTime: '',
        };
      }
      this.isShowScheduleDialog = true;
    },
     async getListSchedule() {
        let loading = this.$loading.show()
        let result = await scheduleService.getListSchedule()
        if (result.message === 'Success') {
          this.schedules = result.data.data
        }
        loading.hide()
    }
  }
}
</script>

<style scoped>

</style>