import Repository from "./Repository.js";
import TokenService from "../helpers/token";
const baseURL =  process.env.VUE_APP_API;
const config = {
    headers: TokenService.getHeader(),
};
export default {
    async getListWorkScheduleApi(params) {
        return await Repository.get(`${baseURL}/user-calendar/list`, {
            params,
            ...config
        });
    },
    async getListShiftApi(params) {
        return await Repository.get(`${baseURL}/shift/shift-management`, {
            params,
            ...config
        });
    },
    async createShiftApi(body) {
        return await Repository.post(`${baseURL}/user-calendar/shift`,body, {
            ...config
        });
    },
    async deleteShiftApi(id) {
        return await Repository.delete(`${baseURL}/user-calendar/shift/${id}`, {
            ...config
        });
    },
    async getUserCalendarApi(params) {
        return await Repository.get(`${baseURL}/user-calendar/list-user`, {
            params,
            ...config
        });
    },
};
