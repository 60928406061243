<template>
  <v-main>
    <div class="label-select">
      <h2>Quản lý nhãn</h2>
      <v-select
        class="mt-4"
        v-model="value"
        :items="items"
        attach
        label="Chips"
        multiple
        outlined
        solo
        flat
        dense
        :menu-props="{ bottom: true, offsetY: true }"
        append-icon="mdi-chevron-down"
      ></v-select>
    </div>
    <div class="labels">
      <div class="all-labels">
        <div class="show-color-picker pb-7">
          <v-btn @click="showColorPicker" small text class="blue--text mr-2">
            <v-icon>mdi-plus-circle-outline</v-icon>
            Thêm nhãn mới
          </v-btn>
        </div>
        <div class="all-page-labels">
          <div
            class="label-item mb-3"
            v-for="(i, index) in labels"
            :key="index"
          >
            <div class="label-item-noti">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <input
                    v-bind="attrs"
                    v-on="on"
                    type="text"
                    placeholder="NHẬP TÊN NHÃN MỚI"
                    v-model="i.label"
                    @change="changeLabel(i.label)"
                    @focusout="
                      clickOutSideInput(i, constant.OUTUPDATELABEL, index)
                    "
                    @keyup.enter="updateLabel(i)"
                    :readonly="i.isReadonly"
                    @dblclick="dblclick(index)"
                  />
                </template>
                <span> {{ i.label }}</span>
              </v-tooltip>

              <div
                @click="changeColor(i)"
                class="label-color mr-1"
                :style="{ background: i.color }"
              ></div>
              <div
                v-show="active == i.label_id"
                class="label-color mr-1"
                :style="{ background: color ? color.hexa : '' }"
              ></div>
            </div>
            <v-icon small class="ml-1" @click="deleteLabel(i.label_id)"
              >mdi-trash-can-outline</v-icon
            >
          </div>
          <div class="label-item" v-show="label">
            <div class="label-item-noti">
              <input
                @change="changeLabel(newLabel)"
                @focusout="clickOutSideInput(newLabel, constant.OUTNEWLABEL)"
                @keyup.enter="addNewLabel(newLabel)"
                v-model="newLabel"
                type="text"
                placeholder="NHẬP TÊN NHÃN MỚI"
                ref="txtLabel"
              />
              <div
                class="label-color mr-1"
                :style="{ background: color ? color.hexa : '' }"
              ></div>
            </div>
            <v-icon small class="ml-1" @click="deleteNewLabel()"
              >mdi-trash-can-outline</v-icon
            >
          </div>
          <div class="color-picker" v-show="labelPicker">
            <v-btn
              color="#289A4C"
              depressed
              class="white--text mr-2 mb-1"
              small
              @click="saveColorLabel"
              >Lưu</v-btn
            >
            <v-btn
              color="#0377D4"
              depressed
              class="white--text mb-1"
              small
              @click="noChangeColor"
              >Hủy</v-btn
            >
            <v-color-picker
              dot-size="27"
              hide-canvas
              hide-inputs
              hide-mode-switch
              mode="hexa"
              show-swatches
              swatches-max-height="147"
              v-model="color"
            ></v-color-picker>
          </div>
        </div>
      </div>
      <div class="note-label">
        <div>
          <b>Sử dụng nhiều nhãn:</b>
          <p>Cho phép bạn gắn đồng thời nhiều nhãn cho hội thoại</p>
        </div>
        <div>
          <b> Khi xoá nhãn hội thoại:</b>
          <p>
            Nhãn hội thoại sẽ bị xoá hoàn toàn khỏi các hội thoại và không thể
            khôi phục
          </p>
        </div>
      </div>
    </div>
  </v-main>
</template>
<script>
import { chatService } from "../../repositories/ChatService";
import { constant } from "../../helpers/constant";
export default {
  data() {
    return {
      constant,
      labels: [],
      color: null,
      label: false,
      labelPicker: false,
      newLabel: "",
      items: ["Tất cả page"],
      value: ["Tất cả page"],
      active: null,
      blockClickOut: false,
      labelChoose: null,
    };
  },
  created() {
    this.getLabels();
  },
  methods: {
    dblclick(inx) {
      this.labels[inx].isReadonly = false;
    },
    focusInput() {
      setTimeout(() => {
        this.$refs.txtLabel.focus();
      }, 100);
    },
    getLabels() {
      chatService
        .getLabels()
        .then((res) => {
          this.labels = res.data.map((s) => {
            return {
              ...s,
              isReadonly: true,
            };
          });
        })
        .catch((err) => {
          this.$swal.fire({
            icon: "error",
            title: err.message,
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },
    changeColor(val) {
      this.blockClickOut = false;
      this.label = false;
      this.labelPicker = true;
      this.active = val.label_id;
      this.labelChoose = val;
    },
    saveColorLabel() {
      if (this.labelChoose) {
        this.labelPicker = false;
        this.updateLabel(this.labelChoose);
      }
      if (this.newLabel !== "") {
        this.addNewLabel(this.newLabel);
      } else {
        this.$nextTick(() => {
          this.focusInput();
        });
      }
    },
    noChangeColor() {
      this.blockClickOut = false;
      this.labelPicker = false;
      this.label = false;
      this.active = null;
    },
    changeLabel(val) {
      if (val !== "") {
        this.blockClickOut = true;
      }
    },
    showColorPicker() {
      this.newLabel = "";
      this.labelChoose = null;
      this.active = null;
      this.label = true;
      this.labelPicker = true;
    },
    addNewLabel(val) {
      if(val.trim() == '') {
        return this.newLabel = ''
      }
      this.blockClickOut = false;
      const params = {
        label: {
          label: val,
          color: this.color.hex,
        },
      };
      chatService.createLabel(params).then(() => {
        this.newLabel = "";
        this.color = null;
        this.labelPicker = false;
        this.label = false;
        this.getLabels();
        this.$swal.fire({
          icon: "success",
          title: "Đã thêm",
          showConfirmButton: false,
          timer: 1500,
        });
      });
    },
    updateLabel(i) {
      if(i.label.trim() == '') {
        return i.label = ''
      }
      this.blockClickOut = false;
      const params = {
        label: {
          label: i.label,
          color: i.color,
        },
      };
      if (i.label_id == this.active) {
        params.label.color = this.color.hexa;
      }
      chatService.updateLabelSetting(i.label_id, params).then(() => {
        this.active = null;
        this.newLabel = "";
        this.color = null;
        this.labelPicker = false;
        this.getLabels();
        this.$swal.fire({
          icon: "success",
          title: "Đã cập nhật",
          showConfirmButton: false,
          timer: 1500,
        });
      });
    },
    deleteLabel(id) {
      this.$swal
        .fire({
          title: "Bạn có chắc chắn?",
          text: "Muốn xóa nhãn không!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Không",
          confirmButtonText: "Xóa",
        })
        .then((result) => {
          if (result.isConfirmed) {
            const params = {
              label_id: id,
            };
            chatService.deleteLabelSetting(params).then(() => {
              this.getLabels();
              this.$swal.fire({
                icon: "success",
                title: "Đã xóa",
                showConfirmButton: false,
                timer: 1500,
              });
            });
          }
        });
    },
    deleteNewLabel() {
      this.newLabel = "";
      this.color = null;
      this.labelPicker = false;
      this.label = false;
    },
    clickOutSideInput(val, key, idx) {
      if (idx) {
        this.labels[idx].isReadonly = true;
      }
      setTimeout(() => {
        if (this.blockClickOut && val) {
          this.$swal
            .fire({
              title: "Bạn có muốn?",
              text: " Lưu nhãn đã thêm hoặc đã chỉnh sửa không!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              cancelButtonText: "Không",
              confirmButtonText: "Lưu",
            })
            .then((result) => {
              if (result.isConfirmed) {
                if (key == constant.OUTNEWLABEL) {
                  if (this.newLabel !== "") {
                    this.addNewLabel(val);
                  } else {
                    this.$nextTick(() => {
                      this.focusInput();
                    });
                  }
                }
                if (key == constant.OUTUPDATELABEL) {
                  this.updateLabel(val);
                }
              }
            });
        }
      }, 100);
    },
  },
};
</script>
<style lang="scss" scoped>
.label-select {
  width: 200px;
  margin: 30px 0px 0px 30px;
  h2 {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #6b778c;
  }
}
.labels {
  display: flex;
  .all-labels {
    width: 600px;
    padding: 30px;
    background: #ffffff;
    border-radius: 4px;
    margin: 0px 30px;
    .show-color-picker {
      display: flex;
      justify-content: right;
    }
    .all-page-labels {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .label-item {
        display: flex;
        text-transform: uppercase;
        height: 50px;
        align-items: center;
        font-size: 14px;
        color: #172b4d;
        .label-item-noti {
          display: flex;
          width: 200px;
          border: 1px solid #ebeaed;
          height: 50px;
          align-items: center;
          border-radius: 4px;
          padding-left: 10px;
          justify-content: space-between;
          input {
            width: 140px;
            outline: none;
            text-transform: uppercase;
          }
          .label-color {
            background: rgb(145, 180, 255);
            height: 36px;
            width: 36px;
            left: 499px;
            top: 278px;
            border-radius: 4px;
          }
        }
      }
    }
  }
}
.color-picker {
  margin: auto;
  margin-top: 29px;
  text-align: right;
}
</style>
