var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"450"},on:{"click:outside":_vm.close},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('div',{staticClass:"schedule-dialog"},[_c('div',{staticClass:"d-flex justify-center",staticStyle:{"font-weight":"bold","font-size":"16px","margin-bottom":"16px"}},[_vm._v(" "+_vm._s(_vm.mode === 'create' ? 'TẠO CA' : 'CHỈNH SỬA CA')+" ")]),_c('div',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-row',{staticStyle:{"margin-top":"8px"}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Tên ca"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Tên ca *","outlined":"","maxlength":"255","counter":""},model:{value:(_vm.innerSchedule.name),callback:function ($$v) {_vm.$set(_vm.innerSchedule, "name", $$v)},expression:"innerSchedule.name"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0 pr-0",attrs:{"cols":"5"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Giờ bắt đầu ca"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"error-messages":errors,"label":"Giờ bắt đầu ca *","items":_vm.timeOption,"outlined":"","clearable":"","no-data-text":" Không có dữ liệu"},model:{value:(_vm.innerSchedule.startTime),callback:function ($$v) {_vm.$set(_vm.innerSchedule, "startTime", $$v)},expression:"innerSchedule.startTime"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",staticStyle:{"position":"relative"},attrs:{"cols":"2"}},[_c('v-icon',{staticStyle:{"position":"absolute","top":"16px","transform":"translateX(-50%)","left":"50%"}},[_vm._v(" mdi-minus ")])],1),_c('v-col',{staticClass:"py-0 pl-0",attrs:{"cols":"5"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Giờ kết thúc ca"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"error-messages":errors,"label":"Giờ kết thúc ca *","outlined":"","items":_vm.timeOption,"clearable":"","no-data-text":" Không có dữ liệu"},model:{value:(_vm.innerSchedule.endTime),callback:function ($$v) {_vm.$set(_vm.innerSchedule, "endTime", $$v)},expression:"innerSchedule.endTime"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Giờ chốt tự động"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"error-messages":errors,"label":"Giờ chốt tự động *","outlined":"","clearable":"","items":_vm.timeOption,"no-data-text":" Không có dữ liệu"},model:{value:(_vm.innerSchedule.autoEndTime),callback:function ($$v) {_vm.$set(_vm.innerSchedule, "autoEndTime", $$v)},expression:"innerSchedule.autoEndTime"}})]}}],null,true)})],1)],1),_c('div',{staticClass:"mt-6 d-flex justify-center"},[_c('v-btn',{staticStyle:{"color":"#828282","margin-right":"8px","width":"100px"},attrs:{"color":"#F2F2F2"},on:{"click":_vm.close}},[_vm._v("Bỏ qua")]),_c('v-btn',{staticStyle:{"color":"white","width":"100px"},attrs:{"color":"#0075FF"},on:{"click":function($event){return handleSubmit(_vm.createSchedule)}}},[_vm._v("Lưu")])],1)]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }