<template>
  <div>
    <v-simple-table class="schedule-table" height="750" fixed-header>
      <template v-slot:default>
        <thead>
          <tr>
            <th style="min-width: 300px; max-width: 300px">Tên ca</th>
            <th style="min-width: 300px; max-width: 300px">Giờ bắt đầu ca</th>
            <th style="min-width: 300px; max-width: 300px">Giờ kết thúc ca</th>
            <th style="min-width: 300px; max-width: 300px">Giờ chốt ca tự động</th>
            <th style="min-width: 100px; max-width: 100px">Thao tác</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in schedules" :key="index">
            <td>
              {{item.name}}
            </td>
            <td>
              {{item.startTime}}
            </td>
            <td>
              {{item.endTime}}
            </td>
            <td>
              {{item.autoEndTime}}
            </td>
            <td>
              <div>
                <v-icon color="#898EFF" v-if="checkPermission('bitu-shift-management_update')" style="margin-right: 6px; cursor:pointer;" @click="handleEditSchedule(item)">mdi-pencil</v-icon>
                <v-icon color="#898EFF" v-if="checkPermission('bitu-shift-management_delete')" style="cursor: pointer;" @click="handleDeleteSchedule(item)">mdi-delete</v-icon>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: "ScheduleManagementTable",
  props: {
    schedules: {
      type: Array,
      required: true,
    }
  },
  methods: {
    handleDeleteSchedule(item) {
      this.$swal.fire({
        text: 'Bạn có chắc muốn xóa ca này ?',
        icon: "warning",
        showCancelButton: true,
        // confirmButtonColor: "#3085d6",
        // cancelButtonColor: "#d33",
        cancelButtonText: 'Hủy ',
        confirmButtonText: "Xóa ca"
      }).then((result) => {
        if (result.isConfirmed) {
          this.$emit('deleteSchedule', item._id)
        }
      });
    },
    handleEditSchedule(item) {
      this.$emit('editSchedule', item)
    },
  }
}
</script>

<style scoped lang="scss">
.schedule-table{
  thead {
    th {
      background: #D8EEFF !important;
      color: #0075FF !important;
      font-size: 14px !important;
      height: 60px !important;
    }
  }
  tbody {
    tr {
      td {
        border-bottom: 1px solid #0000000F;
        border-right: 1px solid #0000000F;
        height: 80px !important;
      }
    }
  }
}
</style>