// import ApiService from "@/repositories/ApiService";
import Repository from "@/repositories/Repository";
import TokenService from "@/helpers/token";
class ScheduleService {
    resource = process.env.VUE_APP_API + '/shift/shift-management'; ''

    getListSchedule(params) {
        return Repository.get(`${this.resource}`, {
            params,
            headers: TokenService.getHeader(),
        })
    }
    deleteSchedule(id) {
        return Repository.delete(`${this.resource}/${id}`, {
            headers: TokenService.getHeader(),
        })
    }
    createSchedule(body) {
        return Repository.post(`${this.resource}`, body, {
            headers: TokenService.getHeader(),
        })
    }
    updateSchedule(id, body) {
        return Repository.put(`${this.resource}/${id}`, body, {
            headers: TokenService.getHeader(),
        })
    }
}

export { ScheduleService }