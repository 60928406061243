<template>
  <v-main>
    <div class="sync-page pa-8">
      <h2>Quản lý fanpage</h2>
      <v-spacer></v-spacer>
      <v-btn class="blue--text" outlined @click="getPageFormFB">
        <v-icon color="#009FE0">mdi-autorenew</v-icon> Đồng bộ fanpage và lấy
        token</v-btn
      >
    </div>
    <form class="fillter-fanpage px-8">
      <div style="width: 318px" class="pr-4">
        <label for="">Tìm kiếm theo tên fanpage</label>
        <v-text-field
          v-model="namePage"
          placeholder="Nhập tên Fanpage tại đây"
          prepend-inner-icon="mdi-magnify"
          dense
          outlined
          solo
          flat
        ></v-text-field>
      </div>
      <div style="width: 318px"  class="pr-4">
        <label for="">Nhân viên</label>
        <v-select
          v-model="customerId"
          :items="users"
          item-text="fullName"
          item-value="id"
          dense
          outlined
          solo
          flat
          multiple
          attach
          placeholder="--Tên nhân viên--"
          append-icon="mdi-chevron-down"
          :menu-props="{ bottom: true, offsetY: true }"
          no-data-text="Không có dữ liệu"
          clearable
        ></v-select>
      </div>
      <div style="width: 318px"  class="pr-4">
        <label for="">Loại hình</label>
        <v-select
          v-model="learningType"
          :items="learning_type"
          item-text="name"
          item-value="id"
          dense
          outlined
          solo
          flat
          multiple
          attach
          placeholder="Loại hình"
          append-icon="mdi-chevron-down"
          no-data-text="Không có dữ liệu"
          clearable
          :menu-props="{ bottom: true, offsetY: true }"
        ></v-select>
      </div>
      <div style="width: 318px"  class="pr-4">
        <label for="">Nhóm ngành</label>
        <v-select
          v-model="schoolLevel"
          :items="school_level"
          item-text="name"
          item-value="id"
          dense
          outlined
          solo
          flat
          multiple
          attach
          placeholder="Nhóm ngành"
          append-icon="mdi-chevron-down"
          no-data-text="Không có dữ liệu"
          clearable
          :menu-props="{ bottom: true, offsetY: true }"
        ></v-select>
      </div>
      <v-btn
        type="submit"
        @click.prevent="searchFillter"
        depressed
        class="white--text mt-6"
        height="40"
        color="#1890FF"
        width="167"
        >Lọc</v-btn
      >
    </form>
    <div class="info-fanpage px-8 mt-2" @scroll="handleScroll">
      <PageItem
        v-for="(item, index) in data"
        :maketingList="maketingList"
        :key="index"
        :item="item"
        :users="users"
        :learning_type="learning_type"
        :school_level="school_level"
        :id="index === 0 ? 'scroll' : ''"
      />
    </div>
  </v-main>
</template>
<script>
import { pageService } from "../../repositories/PageService";
import { constant } from "../../helpers/constant";
import fb from "../../plugins/fb-app";
import PageItem from "./components/PageItem.vue";
import ErpService from "../../repositories/ErpService";
import { settingService } from "../../repositories/SettingService";
import { authService } from "../../repositories/AuthService";

export default {
  components: { PageItem },
  data() {
    return {
      data: [],
      namePage: "",
      customerId: [],
      learningType: [],
      schoolLevel: [],
      constant,
      checkWebhook: true,
      loadPage: 1,
      learning_type: [],
      school_level: [],
      users: [],
      fillter: null,
      fbSecret: "",
      maketingList: [],
    };
  },
  watch: {
    namePage: {
      handler: async function (val) {
        if (val == "" && this.customerId.length == 0 && this.schoolLevel.length == 0 && this.learningType.length == 0) {
          await this.searchFillter();
        }
      },
      deep: true,
    },
    customerId: {
      handler: async function (val) {
        if (val.length == 0 && this.namePage == "" && this.learningType.length == 0 && this.schoolLevel.length == 0) {
          await this.searchFillter();
        }
      },
      deep: true
    },
     learningType: {
      handler: async function (val) {
         if (val.length == 0 && this.namePage == "" && this.customerId.length == 0 && this.schoolLevel.length == 0) {
          await this.searchFillter();
        }
      },
      deep: true
    },
     schoolLevel: {
      handler: async function (val) {
         if (val.length == 0 && this.namePage == "" && this.customerId.length == 0 && this.customerId.length == 0) {
          await this.searchFillter();
        }
      },
      deep: true
    },
  },
  async created() {
    await this.getFBSecret();
    this.getSchoolLevels();
    this.getTypes();
    this.getUsers();
    const loading = this.$loading.show();
    let params = {
      page: this.loadPage,
    };
    const res = await pageService.getListPages(params);
    loading.hide();
    res.data.map((s) => {
      if (s.enable_webhook == 0) {
        return (s.enable_webhook = false);
      }
      if (s.enable_webhook == 1) {
        return (s.enable_webhook = true);
      }
    });
    this.data = res.data;
    this.getMaketingList();
  },
  methods: {
    regLongLiveToken(params) {
      return new Promise((resolve, reject) => {
        try {
          window.FB.api(
            "/oauth/access_token?grant_type=fb_exchange_token&client_id=" +
              fb.appId +
              "&client_secret=" +
              this.fbSecret +
              "&fb_exchange_token=" +
              params.accessToken,
            "get",
            (r) => {
              resolve(r.access_token);
            }
          );
        } catch (error) {
          reject(error);
        }
      });
    },
    connectTo(page) {
      window.FB.api("/" + page.id + "/subscribed_apps", "post", {
        scope: fb.permissions,
        access_token: page.access_token,
        subscribed_fields: [...fb.subscribed_fields],
      });
    },
    getPageFormFB() {
      window.FB.login(
        () => {
          window.FB.api(
            "/me/accounts?fields=picture{url},about,id,general_manager,general_info,founded,genre,page_token,access_token,privacy_info_url,name&limit=500",
            async (resp) => {
              let pages = [];
              for (let page of resp.data) {
                let page_token = await this.regLongLiveToken({
                  accessToken: page.access_token,
                  secret: this.fbSecret,
                });
                pages.push({
                  page_id: page.id,
                  name: page.name,
                  page_token,
                  url: page.page_token,
                  desc: page.about,
                  avatar: page.picture.data.url,
                  enable_webhook: 1,
                });
                this.connectTo(page);
              }
              await pageService.syncFbPage({ pages });
              this.loadPage = 1;
              let params = {
                page: this.loadPage,
              };
              const res = await pageService.getListPages(params);
              this.data = res.data;
              if (res) {
                this.$swal.fire({
                  icon: "success",
                  title: "Đồng bộ thành công",
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
              this.scrollToTop();
            },
            { scope: fb.permissions }
          );
        },
        { scope: fb.permissions }
      );
    },
    async getSchoolLevels() {
      const res = await ErpService.getSchoolLevels();
      this.school_level = res.data;
    },
    async getTypes() {
      const res = await ErpService.getTypes();
      this.learning_type = res.data;
    },
    async getUsers() {
      const res = await ErpService.getUsers();
      this.users = res.data;
    },
    async searchFillter() {
      this.data = [];
      this.loadPage = 1;
      const loading = this.$loading.show();
      let params = {
        keyword: this.namePage.replace(/  +/g, " "),
        e_id: this.customerId,
      };
      if(this.learningType.length > 0) {
        params.learning_type = this.learningType.join(',')
      }else {
        delete params.learning_type
      }
       if(this.schoolLevel.length > 0) {
        params.school_level = this.schoolLevel.join(',')
      }else {
        delete params.school_level
      }
      this.fillter = params;
      if (this.namePage.replace(/ /g, "").length === 0) {
        this.namePage = "";
        loading.hide();
        delete params.keyword;
      }
      const res = await pageService.getListPages(params);
      loading.hide();
      res.data.map((s) => {
        if (s.enable_webhook == 0) {
          return (s.enable_webhook = false);
        }
        if (s.enable_webhook == 1) {
          return (s.enable_webhook = true);
        }
      });

      this.data = res.data;
    },
    async handleScroll(event) {
      if (
        event.target.offsetHeight + event.target.scrollTop >=
        event.target.scrollHeight - 2
      ) {
        this.loadPage = this.loadPage + 1;
        let params = {
          page: this.loadPage,
          ...this.fillter,
        };
        const res = await pageService.getListPages(params);
        if (res.data.length > 0) {
          this.data = this.data.concat(res.data);
        } else {
          this.loadPage = this.loadPage - 1;
        }
      }
    },
    scrollToTop: function () {
      if (document.getElementById("scroll")) {
        document.getElementById("scroll").scrollIntoView();
      }
    },
    async getFBSecret() {
      const res = await settingService.getFBSecret();
      this.fbSecret = res.data[0].secret ?? "";
    },
    async getMaketingList() {
      const res = await authService.getMaketingList();
      this.maketingList = res.data;
      this.maketingList.forEach((item) => {
        item.fullNameCus = `${item.fullName} - ${item.username}`
      });
    },
  },
};
</script>
<style lang="scss">
.sync-page {
  display: flex;
  h2 {
    font-size: 24px;
    color: #6b778c;
    font-weight: 400;
  }
  span.v-btn__content {
    font-size: 14px;
    font-weight: 400;
    text-transform: none;
  }
  button.blue--text.v-btn.v-btn--outlined.theme--light.v-size--default {
    background: white;
  }
}
.fillter-fanpage {
  display: flex;
  label {
    font-size: 14px;
    color: #172b4d;
  }
}
.info-fanpage {
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  max-height: calc(100vh - 273px);
  .v-card {
    margin-bottom: 30px;
  }
  .v-toolbar__content {
    padding: 0px 0px !important;
  }
  label.v-label.theme--light {
    font-size: 14px;
  }

  .v-select__slot {
    font-size: 14px;
  }
  .v-input__control {
    width: 40px;
  }
  a.url-page {
    text-decoration: none;
  }
}
</style>
