<template>
  <v-app id="inspire">
    <AppBar />
    <SideBar />
    <v-main>
      <div class="d-flex align-center my-4 report-main" style="justify-content: end;padding-right: 20px;">
        <div class="d-flex align-center" style="position: relative;justify-content: end;width:350px;">
          <v-btn  color="#3AA55F" @click="isDialogSearch = true"  style="color: white !important;" depressed>
            Tìm kiếm
          </v-btn>
          <v-card v-if="isDialogSearch" class="card-work-schedule"  width="500px" style="margin-top: 0px; margin-bottom: 0px;z-index: 99;position: absolute;right: 0;top:36px" outlined>
            <v-card-title class="text-center d-flex" style="justify-content: space-between">
              <div>Tìm kiếm </div>
              <v-btn icon class="d-flex justify-end" @click="isDialogSearch = false">
                <v-icon class="mx-1">mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text class="pt-2">
              <date-picker
                 class="date-picker-week"
                 ref="picker"
                 style="width: 100% !important; margin-bottom: 16px;"
                 v-model="dateFilter"
                 type="date"
                 range
                 value-type="YYYY-MM-DD"
                 format="DD-MM-YYYY"
                 placeholder="Chọn ngày cần tìm kiếm"
              />
              <v-autocomplete
                  flat
                  :items="listUsers"
                  item-value="id"
                  item-text="fullName"
                  multiple
                  clearable
                  no-data-text="Không có dữ liệu"
                  placeholder="Tìm tư vấn viên"
                  v-model="listQuery.userIds"
                  :menu-props="{ maxHeight: 200, bottom: true }"
              />
            </v-card-text>
            <v-card-actions style="padding-bottom: 25px" class="d-flex align-center justify-center">
              <v-btn color="#FF8057"  style="color: white !important;" @click="handleSearch">
                Tìm kiếm
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </div>
      <table-report
          :data-table="listReport"
      />
    </v-main>
  </v-app>
</template>
<script>
import AppBar from "@/baseComponents/AppBar.vue";
import SideBar from "@/components/SettingChat/SideBar.vue";
import TableReport from "@/components/Report/TableReport.vue";
import ReportService from "@/repositories/ReportService";
import WorkScheduleService from "@/repositories/WorkScheduleService";
import moment from "moment";
export default {
  components: {
    TableReport,
    AppBar, SideBar
  },
  data() {
    return {
      listReport: {},
      dateFilter: [],
      listQuery: {
        fromDate: '',
        toDate: '',
        userIds: [],
      },
      isDialogSearch:false,
      listUsers: [],
    }
  },
  created() {
    this.checkDate()
    this.getListUsers()
    this.getListReport()
  },
  methods: {
    checkDate() {
      const formDate = moment().startOf('isoWeek').format('YYYY-MM-DD');
      const toDate = moment().endOf('isoWeek').format('YYYY-MM-DD');
      this.dateFilter = [formDate,toDate]
    },
    async getListUsers() {
      try {
        const response = await WorkScheduleService.getUserCalendarApi()
        this.listUsers = response.data
      }  catch (e) {
        console.log(e)
      }
    },
  handleSearch() {
    this.getListReport()
    this.isDialogSearch = false
  },
   async getListReport() {
     const loading = this.$loading.show()
     const params = {
        ...this.listQuery
     }
     if(!this.listQuery.userIds.length) {
       delete params.userIds
     }
     if(this.dateFilter.length) {
        params.fromDate = this.dateFilter[0]
        params.toDate = this.dateFilter[1]
     }else {
       delete params.fromDate
       delete params.toDate
     }
     console.log(params, 111)
     try{
       const response = await ReportService.getListReportApi(params)
       loading.hide()
       if(response) {
         this.listReport = response.data
         console.log(this.listReport, 'listReport')
       }
     }catch (e) {
       console.log(e)
     }
    }
  }
}
</script>
<style lang="scss">
.report-main {
  .date-picker-week {
    input {
      color: rgba(0, 0, 0, 0.87);
      font-size: 16px !important;
      height: 40px !important;
      border: none;
      box-shadow: none;
      border-bottom: 1px solid #949494 !important;
      border-radius: 0 !important;
    }
  }
}
</style>