<template>
  <v-main>
    <div class="hide-page">
      <div class="title-hide-page">
        <h3>Tự động ẩn comment (Áp dụng tất cả Page)</h3>
        <v-switch
          @change="changeHideSwitch(notiHide)"
          v-model="notiHide"
          dense
          inset
        ></v-switch>
      </div>
      <v-divider></v-divider>
      <div class="title-hide-page">
        <h3>Tự động ẩn comment: Toán thầy Trung - Luyện Thi ĐH chinh phục 8,9,10</h3>
        <v-switch
          @change="changeHideSwitchPage('106795881154525', notiHide106795881154525)"
          v-model="notiHide106795881154525"
          dense
          inset
        ></v-switch>
      </div>
      <v-divider></v-divider>
      <div class="title-hide-page">
        <h3>Tự động ẩn comment: Vuihoc.vn THPT</h3>
        <v-switch
          @change="changeHideSwitchPage('114086253752069', notiHide114086253752069)"
          v-model="notiHide114086253752069"
          dense
          inset
        ></v-switch>
      </div>
      <v-divider></v-divider>
      <div class="title-hide-page">
        <h3>Tự động ẩn comment: Thầy Huy Tiến - Luyện Lý ĐH nắm chắc 8+</h3>
        <v-switch
          @change="changeHideSwitchPage('100128858573403', notiHide100128858573403)"
          v-model="notiHide100128858573403"
          dense
          inset
        ></v-switch>
      </div>
      <v-divider></v-divider>
      <div class="title-hide-page">
        <h3>Tự động ẩn comment: Luyện thi Đánh Giá Năng Lực ĐHQG</h3>
        <v-switch
          @change="changeHideSwitchPage('101971179274760', notiHide101971179274760)"
          v-model="notiHide101971179274760"
          dense
          inset
        ></v-switch>
      </div>
      <v-divider></v-divider>
    </div>
  </v-main>
</template>
<script>
import { pageService } from "../../repositories/PageService";
export default {
  data() {
    return {
      notiHide: false,
      notiHide106795881154525: false,
      notiHide114086253752069: false,
      notiHide100128858573403: false,
      notiHide101971179274760: false

    };
  },
  created() {
    this.getHideAllPage()
    this.getHideOfPage()
  },
  methods: {
    async getHideAllPage() {
      let res = await pageService.getHideAllPage();
      if (Number(res.data[0].value) == 1) {
        this.notiHide = true;
      } else {
        this.notiHide = false;
      }
    },
    async hideAllPage(params) {
      const res = await pageService.hideAllPage(params);
      if (res.success) {
        this.$swal.fire({
          icon: "success",
          title: "Cập nhật thành công.",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        this.$swal.fire({
          icon: "error",
          title: "Không thành công!",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },

    changeHideSwitch(val) {
      const params = {
        enable_hide_comment: 1,
      };
      if (val) {
        params.enable_hide_comment = 1;
      } else {
        params.enable_hide_comment = 0;
      }
      this.hideAllPage(params);
    },

    changeHideSwitchPage(pageId, val) {
      const params = {
        enable_hide_comment: 1,
        pageId : pageId
      };
      if (val) {
        params.enable_hide_comment = 1;
      } else {
        params.enable_hide_comment = 0;
      }
      this.hideOfPage(params);
    },

    async hideOfPage(params) {
      const res = await pageService.hideCommmentOfPage(params);
      if (res.success) {
        this.$swal.fire({
          icon: "success",
          title: "Cập nhật thành công.",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        this.$swal.fire({
          icon: "error",
          title: "Không thành công!",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    async getHideOfPage() {
      let res = await pageService.getHideOfPage({pageIds: ['106795881154525', '114086253752069', '100128858573403', '101971179274760']});
      this.notiHide106795881154525 = res.data.page106795881154525 == 1 ? true : false;
      this.notiHide114086253752069 = res.data.page114086253752069 == 1 ? true : false;
      this.notiHide100128858573403 = res.data.page100128858573403 == 1 ? true : false;
      this.notiHide101971179274760 = res.data.page101971179274760 == 1 ? true : false;
    },
  },
};
</script>
<style lang="scss" scope>
.hide-page {
  margin: 48px 24px 0px 24px;
  .title-hide-page {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3 {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
    }
  }
}
</style>
