<template>
    <v-app id="inspire">
        <AppBar />
        <SideBar />
        <PageWorkSchedule />
    </v-app>
</template>

<script>
import AppBar from "@/baseComponents/AppBar.vue";
import SideBar from "@/components/SettingChat/SideBar.vue";
import PageWorkSchedule from "@/views/workScheduleManagement/PageWorkSchedule.vue";

export default {
    name: "chat",
    data: () => ({}),
    components: {
        PageWorkSchedule,
        AppBar,
        SideBar,
    },
};
</script>
<style lang="scss" scoped>
#inspire {
    background: #f9fafc;
    .customer {
        background: #ffffff;
        padding: 24px;
        margin: 30px;
        margin-top: 96px;
        border-radius: 10px;
    }
}
</style>
