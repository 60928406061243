<template>
    <div class="report-data px-5 pb-4">
      <v-data-table
          hide-default-footer
          :fixed-header="true"
          :disable-sort="true"
          class="table-report"
          height="calc(100vh - 156px)"
          :headers="headers"
          disable-pagination
          :hide-selected="true"
          :dense="true"
      >
        <template v-slot:header="{ item }">
          <thead>
          <tr>
            <th v-for="header in item"
                :key="header.text"
                :style="`text-align: ${header.align ? header.align : 'left'};`">
              {{ header.text }}
            </th>
          </tr>
          </thead>
        </template>
        <template v-slot:body>
          <tbody>
            <tr>
              <td class="freeze-row" colspan="3" style="font-weight: 500 !important;">Tổng</td>
              <td class="freeze-row">{{ checkData(dataTable, 'nominalCount') }}</td>
              <td class="freeze-row">{{ checkData(dataTable, 'interactionCount') }}</td>
              <td class="freeze-row">{{ checkData(dataTable, 'phoneConversionCount') }}</td>
              <td class="freeze-row">{{ checkData(dataTable, 'orderConversionCount') }}</td>
              <td class="freeze-row">{{ checkData(dataTable, 'conversionRateByConversation') }}</td>
              <td class="freeze-row">{{ checkData(dataTable, 'conversionRateByPhone') }}</td>
              <td class="freeze-row">{{ formatPrice(checkData(dataTable, 'initRevenue')) }}</td>
              <td class="freeze-row">{{ formatPrice(checkData(dataTable, 'successRevenue')) }}</td>
            </tr>
          </tbody>
          <template v-if="dataTable && dataTable.detail &&  dataTable.detail.length > 0">
            <tbody v-for="(item, index) in dataTable.detail" :key="'detail'+index">
              <template v-for="(userShift, indexUserShift) in item.userShifts">
                <template v-for="(report, indexReport) in userShift.shiftReport">
                  <tr v-if="indexUserShift === 0 && indexReport === 0">
                      <td :rowspan="item.userShifts.length * userShift.shiftReport.length">{{ item.user.fullName }}</td>
                      <td :rowspan="userShift.shiftReport.length">{{ userShift.shift.name }}</td>
                      <td>{{ report.conversationType }}</td>
                      <td>{{ report.nominalCount }}</td>
                      <td>{{ report.interactionCount }}</td>
                      <td>{{ report.phoneConversionCount }}</td>
                      <td>{{ report.orderConversionCount }}</td>
                      <td>{{ report.conversionRateByConversation }}</td>
                      <td>{{ report.conversionRateByPhone }}</td>
                      <td>{{ report.initRevenue ? formatPrice(report.initRevenue) : 0 }}</td>
                      <td>{{ report.successRevenue ? formatPrice(report.successRevenue) : 0 }}</td>
                  </tr>
                  <tr v-else-if="indexReport === 0">
                    <td :rowspan="userShift.shiftReport.length">{{ userShift.shift.name }}</td>
                    <td>{{ report.conversationType }}</td>
                    <td>{{ report.nominalCount }}</td>
                    <td>{{ report.interactionCount }}</td>
                    <td>{{ report.phoneConversionCount }}</td>
                    <td>{{ report.orderConversionCount }}</td>
                    <td>{{ report.conversionRateByConversation }}</td>
                    <td>{{ report.conversionRateByPhone }}</td>
                    <td>{{ report.initRevenue ? formatPrice(report.initRevenue) : 0 }}</td>
                    <td>{{ report.successRevenue ? formatPrice(report.successRevenue, 0) : 0 }}</td>
                  </tr>
                  <tr v-else>
                    <td>{{ report.conversationType }}</td>
                    <td>{{ report.nominalCount }}</td>
                    <td>{{ report.interactionCount }}</td>
                    <td>{{ report.phoneConversionCount }}</td>
                    <td>{{ report.orderConversionCount }}</td>
                    <td>{{ report.conversionRateByConversation }}</td>
                    <td>{{ report.conversionRateByPhone }}</td>
                    <td>{{ report.initRevenue ? formatPrice(report.initRevenue) : 0 }}</td>
                    <td>{{ report.successRevenue ? formatPrice(report.successRevenue) : 0 }}</td>
                  </tr>
                </template>
              </template>
            </tbody>
          </template>
        </template>
      </v-data-table>
    </div>
</template>
<script>
export default {
  name: 'TableReport',
  props: {
    dataTable: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      headers: [
        { text: 'Tên trực page', value: '', align: '', width: '180px' },
        { text: 'Ca trực', value: '', align: '', width: '85px' },
        { text: 'Loại hội thoại', value: '', align: '', width: '135px ' },
        { text: 'Số hội thoại Danh định', value: '', align: '', width: '95px' },
        { text: 'Số hội thoại đã tương tác', value: '', align: '', width: '110px' },
        { text: 'Số điện thoại mang về', value: '', align: '', width: '100px' },
        { text: 'Đơn hàng ', value: '', align: '', width: '85px' },
        { text: 'TLC ( Đơn / HTTT )', value: '', align: '', width: '120px' },
        { text: 'TLC  ( Đơn / cts )', value: '', align: '', width: '120px' },
        { text: 'DT khởi tạo', value: '', align: '', width: '95px' },
        { text: 'DT thành công', value: '', align: '', width: '105px' },
      ],
    }
  },
  methods: {
    checkData(item, key) {
      return item && item.total && item.total[key] ? item.total[key] : 0
    }
  }
}
</script>
<style lang="scss">
.table-report {
  .v-data-table--dense th {
    height: 55px !important;
    z-index: 10;
  }

  table {
    border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
  }

  .v-data-table__empty-wrapper {
    td {
      width: 100%;
      text-align: left;
      font-weight: 400;
      color: red !important;
      border-top: 0;
      border-right: 1px solid #d2d2d2;
      border-left: 1px solid #d2d2d2;
      border-bottom: 1px solid #d2d2d2;
    }
  }

  table {
    .freeze-row {
      position: sticky;
      top: 67px !important;
      z-index: 3 !important;
      background: white;
    }
    th {
      font-weight: 500 !important;
      background: #ebf4ff !important;
      color: rgba(0, 117, 255, 1) !important;
      //z-index: 2 !important;
    }

    td, th {
      border: thin solid rgba(0, 0, 0, 0.12) !important;
      padding: 12px 8px !important;
      font-weight: 400;
      height: 50px !important;
      font-size: 14px !important;
      line-height: 1.5 !important;
      letter-spacing: 0;
    }

    td {
      color: #000000;
      padding: 8px !important;
      //z-index: 1 !important;
    }
  }
  table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: transparent !important;
  }
}
</style>