<template>
  <v-dialog v-model="isShow" @click:outside="close" width="450">
    <div class="schedule-dialog">
      <div class="d-flex justify-center" style="font-weight: bold; font-size: 16px; margin-bottom: 16px">
        {{mode === 'create' ? 'TẠO CA' : 'CHỈNH SỬA CA'}}
      </div>
      <div>
        <validation-observer v-slot="{ handleSubmit }" ref="observer">
          <v-row style="margin-top: 8px">
            <v-col cols="12" class="py-0">
              <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Tên ca"
              >
                <v-text-field
                    :error-messages="errors"
                    label="Tên ca *"
                    outlined
                    v-model="innerSchedule.name"
                    maxlength="255"
                    counter
                >

                </v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="5" class="py-0 pr-0">
              <validation-provider
                v-slot="{ errors }"
                rules="required"
                name="Giờ bắt đầu ca"
              >
                <v-autocomplete
                  :error-messages="errors"
                  label="Giờ bắt đầu ca *"
                  :items="timeOption"
                  outlined
                  clearable
                  v-model="innerSchedule.startTime"
                  no-data-text=" Không có dữ liệu"
                >
                </v-autocomplete>
              </validation-provider>
            </v-col>
            <v-col cols="2" class="py-0" style="position: relative">
              <v-icon style="position: absolute; top: 16px; transform: translateX(-50%); left: 50%">
                mdi-minus
              </v-icon>
            </v-col>
            <v-col cols="5" class="py-0 pl-0">
              <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Giờ kết thúc ca"
              >
                <v-autocomplete
                    :error-messages="errors"
                    label="Giờ kết thúc ca *"
                    outlined
                    :items="timeOption"
                    clearable
                    v-model="innerSchedule.endTime"
                    no-data-text=" Không có dữ liệu"
                >
                </v-autocomplete>
              </validation-provider>
            </v-col>
            <v-col cols="12" class="py-0">
              <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Giờ chốt tự động"
              >
                <v-autocomplete
                    :error-messages="errors"
                    label="Giờ chốt tự động *"
                    outlined
                    clearable
                    :items="timeOption"
                    v-model="innerSchedule.autoEndTime"
                    no-data-text=" Không có dữ liệu"
                >
                </v-autocomplete>
              </validation-provider>
            </v-col>
          </v-row>

          <div class="mt-6 d-flex justify-center">
            <v-btn color="#F2F2F2" style="color: #828282; margin-right: 8px; width: 100px" @click="close">Bỏ qua</v-btn>
            <v-btn style="color: white; width: 100px" color="#0075FF" @click="handleSubmit(createSchedule)">Lưu</v-btn>
          </div>
        </validation-observer>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import {ScheduleService} from "@/repositories/ScheduleService";
const scheduleService = new ScheduleService;
export default {
  name: "ScheduleDialog",
  props: {
    mode: {
      type: String,
      required: true,
    },
    isShow: {
      type: Boolean,
      required: true,
    },
    schedule: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      timeOption: [],
      innerSchedule: {
        name: '',
        startTime: '',
        endTime: '',
        autoEndTime: '',
      },
    }
  },
  created() {
    this.generateTime();
  },
  watch: {
    isShow(val) {
      if(val) {
        this.$nextTick(() => {
          this.$refs.observer.reset();
        })
      }
    },
    schedule: {
      handler(val) {
        this.innerSchedule = JSON.parse(JSON.stringify(val))
      },
      deep: true,
    }
  },
  methods: {
    generateTime() {
      for (let h = 0; h <=23; h++) {
        for (let m = 0; m <= 59; m++) {
          let hh = '';
          let mm = '';
          if (h < 10) {
            hh = '0'.concat(h);
          } else {
            hh = h.toString();
          }
          if (m < 10) {
            mm = '0'.concat(m);
          }  else {
            mm = m.toString();
          }
          this.timeOption.push(`${hh}:${mm}`)
        }
      }
    },
    async createSchedule() {
      let result = null;
      if (this.mode === 'create') {
        result = await scheduleService.createSchedule(this.innerSchedule);
      }
      else if (this.mode === 'edit') {
        result = await scheduleService.updateSchedule(this.innerSchedule._id, {
          name: this.innerSchedule.name,
          endTime: this.innerSchedule.endTime,
          startTime: this.innerSchedule.startTime,
          autoEndTime: this.innerSchedule.autoEndTime,
        });
      }
      if (result.message === 'Success') {
        this.$swal.fire({
          title: this.mode === 'create' ? 'Tạo ca thành công' : 'Chỉnh sửa ca thành công',
          icon: 'success',
          width: 450,
        })
        this.$refs.observer.reset()
        this.$emit('close')
        this.$emit('refreshData')
      } else {
        this.$swal.fire({
          title: this.mode === 'create' ? 'Có lỗi khi tạo ca' : 'Có lỗi khi chỉnh sửa ca',
          text: result.message,
          icon: 'error',
          width: 450,
        })
      }
    },
    close() {
      this.$nextTick(() => {
        this.$refs.observer.reset();
      })
      // this.$refs.observer.reset();
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.schedule-dialog {
  background: white;
  padding: 17px 17px 36px;
}
</style>