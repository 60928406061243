<template>
  <v-card width="346" class="mr-5 px-5 pb-4">

    <!--MESSAGE-DIALOG-->
    <v-dialog
      v-model="assignDialog"
      persistent
      max-width="800px"
      max-height="500px">
      <v-card>
        <v-card-title class="box-title-custom">
          <p class="text-h5 text-center text-uppercase">
            Danh sách tư vấn viên được nhận phiếu
          </p>
          <v-btn icon class="float-right btn-custom " @click="closeDialog">
            <v-icon class="mx-1">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <p class="text-info cusor-pointer"><span @click="selectAll">Chọn tất cả </span> | <span @click="unSelectAll"> Bỏ chọn tất cả</span></p>
              </v-col>
            </v-row>
            <v-row class="select-assign-box">
              <v-col class="item-user-assign" cols="4" v-for="(itemAs, indexAs) in users" :key="indexAs">
                <v-card
                  class="pa-2"
                  outlined
                  tile
                >
                  <v-row style="background: #F4F5F7">
                    <v-col class="box-select-user" cols="2">
                      <v-checkbox
                        v-model="assignSelected"
                        :value="itemAs.id"
                        :label="''"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="2">
                      <v-avatar size="36px">
                        <img
                          alt="Avatar"
                          :src="imgUser"
                          v-on:error="itemAs.avatarId=imgUser"
                        >
                      </v-avatar>
                    </v-col>
                    <v-col class="box-select-user" cols="8">
                      <strong class="text-blue">{{ itemAs.fullName }}</strong>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            <v-row class="justify-end box-action">
              <v-btn @click="closeDialog">Hủy</v-btn>
              <v-btn color="primary" @click="submitAssign(dataPropItem.page_id)">
                Xác nhận
              </v-btn>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--END-DIALOG-->

    <v-app-bar color="white" height="82" style="box-shadow: none !important">
      <template>
        <v-list-item-avatar size="50">
          <v-img
            :alt="`${dataPropItem.desc} avatar`"
            :src="dataPropItem.avatar ? dataPropItem.avatar : imgGroup"
          ></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="blue--text"
            >{{ dataPropItem.name }}
          </v-list-item-title>
          <v-list-item-subtitle class="blue--text mt-1" style="font-size: 12px">
            <a
              class="url-page"
              :href="`https://www.facebook.com/${dataPropItem.url}`"
              target="_blank"
              rel="noopener noreferrer"
            >
              <v-icon color="blue">mdi-facebook</v-icon>/{{ dataPropItem.url }}
            </a></v-list-item-subtitle
          >
        </v-list-item-content>

        <v-list-item-action>
          <v-list-item-action-text
            v-text="dataPropItem.action"
          ></v-list-item-action-text>
        </v-list-item-action>
      </template>
      <v-switch
        @change="enableWebhook(dataPropItem.page_id, dataPropItem.enable_webhook)"
        v-model="dataPropItem.enable_webhook"
        dense
        inset
      ></v-switch>
    </v-app-bar>
    <v-divider></v-divider>
    <v-list-item-subtitle class="mt-3" style="font-size: 14px; color: #6b778c">
      Có {{ item.assigns.length }} tư vấn viên đang nhận xử
      lý</v-list-item-subtitle
    >
    <div style="display: flex" class="mt-2 mb-6">
      <div v-for="(i, index) in item.assigns" :key="index">
        <v-avatar color="gray" class="mr-5" size="35" v-if="index < 4">
          <v-img :src="i.img ? i.img : imgUser"></v-img
        ></v-avatar>
      </div>

      <v-avatar
        class="mr-5"
        size="35"
        v-if="item.assigns.length > 4"
        color="#009FE0"
      >
        <span style="color: white; font-size: 14px"
          >+{{ item.assigns.length - 4 }}</span>
      </v-avatar>
        <v-avatar class="cusor-pointer" size="35" color="#F5F9FA" @click="assignDialog = true">
          <v-icon color="#009FE0" small>mdi-plus</v-icon>
        </v-avatar>
    </div>
    <div class="info-class-item" style="display: flex">
      <v-select
        @change="changeTypes(dataPropItem.page_id, dataPropItem.learning_type)"
        v-model="dataPropItem.learning_type"
        class="pr-1"
        placeholder="Loại hình"
        :items="learning_type"
        item-text="name"
        item-value="id"
        dense
        solo
        outlined
        flat
        attach
        id="request"
        append-icon="mdi-chevron-down"
        :return-object="true"
        :menu-props="{ bottom: true, offsetY: true }"
        clearable
        multiple
        no-data-text="Không có dữ liệu"
        ><span>Chưa chọn loại hình</span>
      </v-select>
      <v-select
        @change="changeSchool(dataPropItem.page_id, dataPropItem.school_level)"
        v-model="dataPropItem.school_level"
        class="pl-1"
        solo
        placeholder="Nhóm ngành"
        :items="school_level"
        item-text="name"
        item-value="id"
        dense
        outlined
        flat
        id="request"
        append-icon="mdi-chevron-down"
        :menu-props="{ bottom: true, offsetY: true }"
        multiple
        :return-object="true"
        clearable
        attach
        no-data-text="Không có dữ liệu"
        ></v-select
      >
    </div>
    <div class="info-class-item">
      <v-select
        @change="changeProduct(dataPropItem.page_id, dataPropItem.product_group)"
        v-model="dataPropItem.product_group"
        solo
        placeholder="Nhóm sản phẩm"
        :items="product_group"
        item-text="name"
        item-value="id"
        dense
        outlined
        flat
        id="request"
        append-icon="mdi-chevron-down"
        :menu-props="{ bottom: true, offsetY: true }"
        multiple
        :return-object="true"
        attach
        clearable
        no-data-text="Không có dữ liệu"
      ></v-select>
    </div>
    <div class="info-class-item">
      <v-select
        v-model="dataPropItem.userMarketing"
        @change="btnAccept = true"
        solo
        placeholder="Phụ trách Maketing"
        :items="maketingList"
        item-text="fullNameCus"
        item-value="id"
        dense
        outlined
        flat
        id="request"
        append-icon="mdi-chevron-down"
        :menu-props="{ bottom: true, offsetY: true }"
        attach
        clearable
        no-data-text="Không có dữ liệu"
      ></v-select>
    </div>
    <div class="btn-run-save">
      <span v-if="dataPropItem.is_synced === constant.ISSYNC"
        >Lấy dữ liệu cũ
        <v-btn outlined small class="blue--text ml-1" @click="syncOldData(dataPropItem)">Run</v-btn></span
      >
      <span style="color: green" v-if="dataPropItem.is_synced === constant.ISSYNCED"
        >Đã lấy dữ liệu trong 30 ngày</span
      >
      <div v-show="btnAccept">
        <v-btn small  @click="unAcccept" class="black--text ml-2" color="#EBEAED">Hủy</v-btn>
      <v-btn small class="white--text ml-2" color="#0377D4" @click="updatePage(dataPropItem.page_id,dataPropItem)">Lưu</v-btn>
      </div>
    </div>
  </v-card>
</template>
<script>
import { constant } from "../../../helpers/constant";
import { pageService } from "../../../repositories/PageService";
import ErpService from "../../../repositories/ErpService";

export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    learning_type: {
      type: Array,
      default: () => [],
    },
    school_level: {
      type: Array,
      default: () => [],
    },
    users: {
      type: Array,
      default: () => [],
    },
    maketingList: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
     item: {
      handler: function () {
        this.assignSelected = this.item.assigns.map((item) => item.e_id)
      },
      deep: true,
    },
  },

  data() {
    return {
      imgGroup:
        "https://developer.jboss.org/images/jive-sgroup-default-portrait-large.png",
      imgUser:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4MJsV58xh-vFYz3u4WMpS65vCvnGwBYc54SfmKToORTHdZALkZNGvpBlt4dc45A0M-y0&usqp=CAU",
      constant,
      product_group: [],
      dialog: false,
      selected: [],
      assignDialog: false,
      btnAccept: false,
      assignSelected: this.item.assigns.map((item) => item.e_id),
      cloneApi: JSON.parse(JSON.stringify(this.item)),
      dataPropItem: {},
    };
  },
  created() {
    this.dataPropItem = JSON.parse(JSON.stringify(this.item));
    this.dataPropItem.userMarketing = null;
    if (this.dataPropItem.user_marketing.length) {
      this.dataPropItem.userMarketing = this.dataPropItem.user_marketing.map((item) => item.id)[0];
    }
    
    this.getProducts(this.dataPropItem.learning_type, this.dataPropItem.school_level);
  },
  methods: {
    async getProducts(type, school) {
      // this.dataPropItem.product_group = []
      this.product_group = [];
      if (type.length > 0 && school.length > 0) {
        type = type.map((s) => {
          return s.id;
        });
        school = school.map((s) => {
          return s.id;
        });
        let params = {
          schoolLevelIds: school,
          typeIds: type
        }
        const res = await ErpService.getProducts(params);
        if(res.data) {
          for(var x in res.data) {
            this.product_group.push({
              id: res.data[x].typeId+"|"+res.data[x].schoolLevelId+"|"+res.data[x].id,
              name: res.data[x].name
            });
          }
        } else {
          this.product_group = [];
        }
      }
    },
    changeTypes(pageId, learningType) {
      this.dataPropItem.product_group = []
      this.btnAccept = true
      if (learningType.length > 0) {
        this.getProducts(learningType, this.dataPropItem.school_level);
      } else {
        this.getProducts([], []);
        this.changeProduct(pageId, []);
      }
    },
    changeSchool(pageId, schoolLevel) {
      this.dataPropItem.product_group = []
       this.btnAccept = true
      if (schoolLevel.length > 0) {
        this.getProducts(this.dataPropItem.learning_type, schoolLevel);
      } else {
        this.getProducts([], []);
        this.changeProduct(pageId, []);
      }
    },
    changeProduct() {
       this.btnAccept = true;
    },
    enableWebhook(pageId, item) {
      if (item) {
        item = 1;
      } else {
        item = 0;
      }
      const params = {
        page: {
          enable_webhook: item,
        },
      };
      this.updatePage(pageId, params);
    },
    async updatePage(pageId, item) {
      let params
      if(item.page) {
        params = item
      }else {
         let schoolLevelId = item.school_level.map((s) => {
        return {
          id: s.id,
          name: s.name,
        };
      });
      let productGroupId = item.product_group.map((s) => {
        return {
          id: s.id,
          name: s.name,
        };
      });
      let types = item.learning_type.map((s) => {
        return {
          id: s.id,
          name: s.name,
        };
      });

      const user_marketing = this.maketingList.filter((item) => this.dataPropItem.userMarketing == item.id).map((item) => {
        return {
          id: item.id,
          full_name: item.fullName,
          username: item.username,
        };
      });
      params = {
        page: {
          school_level: schoolLevelId,
          product_group: productGroupId,
          learning_type: types,
          user_marketing,
        },
      };
      } 
      if(params.page.school_level && params.page.school_level.length == 0 || params.page.learning_type && params.page.learning_type.length == 0) {
        this.dataPropItem.product_group = []
        params.page.product_group = []
      }
      const loading = this.$loading.show();
      await pageService.updatePage(pageId, params).then((res) => {
        this.cloneApi = JSON.parse(JSON.stringify(this.dataPropItem))
        this.btnAccept = false
        this.$swal.fire({
          icon: "success",
          title: res.message,
          showConfirmButton: false,
          timer: 1500,
        });
        this.assignDialog = false;
        if (params.page.assigns) {
          this.item.assigns = params.page.assigns;
        }
        
      }).catch(() => {
        this.$swal.fire({
          icon: "error",
          title: 'Cập nhật không thành công',
          showConfirmButton: false,
          timer: 1500,
        });
      });
      loading.hide();
    },
    unAcccept() {
      this.btnAccept = false
      this.getProducts(JSON.parse(JSON.stringify(this.cloneApi)).learning_type, JSON.parse(JSON.stringify(this.cloneApi)).school_level);
      this.dataPropItem =  JSON.parse(JSON.stringify(this.cloneApi))
    },
    syncOldData(item) {
      pageService.syncOldData(item.page_id, {}).then(
        (res) => {
          this.$swal.fire({
            icon: "success",
            title: res.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      ).catch(() => {
        this.$swal.fire({
          icon: "error",
          title: 'Cập nhật không thành công',
          showConfirmButton: false,
          timer: 1500,
        });
      });
    },
    selectAll() {
      this.assignSelected = this.users.map(item => item.id);
    },
    unSelectAll() {
      this.assignSelected = [];
    },
    submitAssign(pageId) {
      const assign = [];
      this.users.filter((item) => {
        if (this.assignSelected.includes(item.id)) {
          assign.push({
            e_id: item.id,
            e_name: item.fullName,
            e_avatar: 'avatar',
            // e_avatar: item.avatarId ? item.avatarId : 'avatar',
          });
        }
      });
      const params = {
        page: {
          assigns: assign,
        },
      };
      this.updatePage(pageId, params);
    },
    inSelected(item) {
      return this.assignSelected.includes(item.id);
    },
    closeDialog() {
      this.assignDialog = false;
      this.assignSelected = this.item.assigns.map((item) => item.e_id);
    }
  },
};
</script>
<style lang="scss" scoped>
.list-users {
  ul {
        list-style: none;
    display: flex;
    flex-wrap: wrap;
    li {
      label {
        display: flex;
    align-items: center;
      }
    }
  }
}
.box-select-user {
  padding-top: 16px;
}
.box-title-custom {
  display: block;
  p {
    position: relative;
  }
  .btn-custom {
    position: absolute;
    top: 15px;
    right: 20px;
  }
}
.select-assign-box {
  max-height: 200px;
  overflow-y: auto;
}
.box-action {
  padding-top: 20px;
}
.btn-run-save {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
