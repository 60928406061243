
import Repository from "./Repository.js";
import TokenService from "../helpers/token";
const baseURLReport =  `${process.env.VUE_APP_API_REPORT}/api/v1`;
const config = {
  headers: TokenService.getHeader(),
};
export default {
  async getListReportApi(params) {
    return await Repository.get(`${baseURLReport}/shift-report`, {
      params,
      ...config
    });
  },
};
