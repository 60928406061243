<template>
  <v-app id="inspire">
    <!-- <SystemBar /> -->

    <AppBar />

    <SideBar />

    <ScheduleManagement />
  </v-app>
</template>

<script>
import AppBar from "../baseComponents/AppBar.vue";
import SideBar from "../components/SettingChat/SideBar.vue";
import ScheduleManagement from "@/components/Schedule/ScheduleManagement";

export default {
  name: "Schedule",
  components: {
    ScheduleManagement,
    AppBar,
    SideBar,
    // LabelManagement,
    // OtherSettings
  },
}
</script>

<style scoped>
#inspire {
  background: #f9fafc;
.customer {
  background: #ffffff;
  padding: 24px;
  margin: 30px;
  margin-top: 96px;
  border-radius: 10px;
}
}
</style>