<template>
    <v-main>
        <div class="work-schedule-management px-5 py-4">
            <div class="d-flex align-center" style="justify-content: space-between">
                <div class="d-flex align-center" style="column-gap: 10px;width: 100%">
                    <h3 style="min-width: max-content">Giờ làm việc</h3>
                    <div class="d-flex align-center" style="column-gap: 10px;flex-wrap: wrap">
                        <div style="margin-left: 20px" v-for="(shift, index) in listShiftName" :key="index">
                            <div class="d-flex align-center" style="column-gap: 7px;font-size: 14px; font-weight: 500; font-style: italic;min-width: max-content">
                                <div style="width: 5px;height: 5px; background-color: #717171; border-radius: 100%"></div>
                                <div>{{ shift.name }}: <span>{{ shift.startTime }}</span> <span>-  {{ shift.endTime }}</span> </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex align-center" style="position: relative;justify-content: end" :style="isDialogSearch ? 'width:500px' : ''">
                    <v-btn  color="#3AA55F" @click="isDialogSearch = true"  style="color: white !important;" depressed>
                        Tìm kiếm
                    </v-btn>
                    <v-card v-if="isDialogSearch" class="card-work-schedule"  width="500px" style="margin-top: 0px; margin-bottom: 0px;z-index: 99;position: absolute;right: 0;top:36px" outlined>
                        <v-card-title class="text-center d-flex" style="justify-content: space-between">
                            <div>Tìm kiếm </div>
                            <v-btn icon class="d-flex justify-end" @click="isDialogSearch = false">
                                <v-icon class="mx-1">mdi-close</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-card-text class="pt-2">
                            <date-picker class="date-picker-week"
                                         ref="picker"
                                         style="width: 100% !important; margin-bottom: 16px;"
                                         v-model="dataWeek"
                                         value-type="w"
                                         format="[Tuần] ww"
                                         type="week"
                                         placeholder="Chọn tuần cần lọc"
                            />
                            <v-autocomplete
                              flat
                              :items="listUsers"
                              item-value="id"
                              item-text="fullName"
                              multiple
                              clearable
                              no-data-text="Không có dữ liệu"
                              placeholder="Tìm tư vấn viên"
                              v-model="listQuery.userIds"
                              :menu-props="{ maxHeight: 200 }"
                            ></v-autocomplete>
                            <v-autocomplete
                              :items="listShiftName"
                              item-value="name"
                              item-text="name"
                              multiple
                              clearable
                              no-data-text="Không có dữ liệu"
                              placeholder="Tìm theo ca"
                              v-model="listQuery.shiftNames"
                              :menu-props="{ maxHeight: 200 }"
                            ></v-autocomplete>
                            <v-autocomplete
                              :items="listStatus"
                              item-value="id"
                              clearable
                              placeholder="Trạng thái chốt ca"
                              item-text="name"
                              no-data-text="Không có dữ liệu"
                              v-model="listQuery.statusId"
                              :menu-props="{ maxHeight: 200 }"
                            ></v-autocomplete>
                        </v-card-text>
                        <v-card-actions style="padding-bottom: 25px" class="d-flex align-center justify-center">
                            <v-btn color="#FF8057"  style="color: white !important;" @click="handleSearch">
                                Tìm kiếm
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </div>

            </div>
            <div class="work-schedule-management__table" style="position: relative">

                <table class="table-parent" cellspacing="" style="width: 100%;overflow-x: auto;">
                    <thead style="position: sticky;top: 71.05px; z-index: 2">
                    <th class="text-center" style="background: #D8EEFF;height: 100%;color: #0075FF;font-weight: 500">
                        Tư vấn viên
                    </th>
                    <!-- <th>Tất cả</th> -->
                    <th v-for="(day, iDay) in headers" :key="iDay" class="text-center" style="background: #D8EEFF;height: 100%;color: #0075FF">
                        <div style="font-weight: 500 !important;">{{ day.date }} <span v-if="day.dayOfWeek"> ({{day.dayOfWeek}})</span>
                            <div style="margin: 0; padding: 0;font-weight: 600; !important;" v-if="day.isCurrentDay">( Hôm nay )</div>
                        </div>
                    </th>
                    </thead>
                    <tbody>
                    <template v-for="(schedule, key) in listSchedule">
                        <tr :key="key+ 'abc'">
                            <td class="text-center case" style="white-space: pre-line;width: 170px;font-weight: 500;min-width: 170px;">
                                <span>{{ schedule.user.lastname +" "+ schedule.user.firstname }}</span>
                            </td>
                            <td v-for="(time, key_time) in schedule.calendar" :key="key_time">
                                <table class="table-child" style="width: 100%;height: 100%">
                                    <tbody>
                                    <tr v-for="(study, studyId) in time.shift" :key='studyId'>
                                        <td style="padding:10px 5px !important"
                                            :style="checkStyleStudy(study)"
                                            @click="!study.registerAt || study.registerAt && !study.status ? (study.registerAt && !study.status ? deleteShift(study) : createShift(schedule.user, time, study)) : ''"
                                        >
                                            <div class="d-flex align-center" style="justify-content: space-between">
                                                <div :style=" study.status && study.status.name ? 'width: calc(100% - 85px)' : ''">{{ study.name }}</div>
                                                <div v-if="study.status && study.status.name" :class="checkStatus(study.status.id)">
                                                     {{ study.status.name }}
                                                </div>
                                            </div>
                                            <div v-if="study.checkInAt || study.checkOutAt" style="display: flex;align-items: center;margin-top: 3px;">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24">
                                                    <g fill="none">
                                                        <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                                                        <path fill="white" d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12S6.477 2 12 2m0 2a8 8 0 1 0 0 16a8 8 0 0 0 0-16m0 2a1 1 0 0 1 .993.883L13 7v4.586l2.707 2.707a1 1 0 0 1-1.32 1.497l-.094-.083l-3-3a1 1 0 0 1-.284-.576L11 12V7a1 1 0 0 1 1-1" />
                                                    </g>
                                                </svg>
                                               <div style="font-size: 13px !important;font-style: italic">
                                                   <span style="margin-left: 6px;" v-if="study.checkInAt">{{ formatTime(study.checkInAt) }}  </span>
                                                   <span> - </span>
                                                   <span v-if="study.checkOutAt"> {{ formatTime(study.checkOutAt) }}</span>
                                               </div>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </template>
                    </tbody>
                </table>
            </div>
        </div>
    </v-main>
</template>
<script>
import WorkScheduleService from "@/repositories/WorkScheduleService";
import {constant} from "@/helpers/constant";
import moment from "moment";
export default {
    name: 'PageWorkSchedule',
    components: {

    },
    data() {
        return {
            isDialogSearch: false,
            dataWeek: '',
            listQuery: {
                fromDate: '',
                toDate: '',
                userIds: [],
                shiftNames: [],
                statusId: [],
            },
            listShiftName: [],
            listUsers: [],
            listStatus: [
                { id: 1, name: `Đã chốt ca` },
                { id: 2, name: `Chốt tự động` },
                { id: 3, name: `Không mở ca` }
              ],
            headers: [],
            listSchedule: [],
        }
    },
    created() {
        const currentWeek = this.getCurrentWeek();
        this.dataWeek = currentWeek;
        this.calculateStartEndDates(currentWeek);
        Promise.all([
            this.getWorkScheduleService(),
            this.getListUsers(),
            this.getListShift()
        ])
    },
    computed: {
        constant() {
            return constant
        }

    },
    watch: {
        dataWeek: {
            handler(newVal) {
                if (newVal) {
                    this.calculateStartEndDates(newVal);
                }else {
                    this.listQuery.fromDate = ''
                    this.listQuery.toDate = ''
                }
            },
            immediate: true
        }
    },
    methods: {
        getCurrentWeek() {
            const today = new Date();
            const weekNumber = this.getWeekNumber(today);
            return `${weekNumber}`;
        },
        getWeekNumber(date) {
            const startOfYear = new Date(date.getFullYear(), 0, 1);
            const firstMonday = new Date(startOfYear);
            firstMonday.setDate(firstMonday.getDate() + (1 - startOfYear.getDay() + 7) % 7);
            const diff = date - firstMonday;
            const oneWeek = 604800000;
            return Math.ceil((diff + 1) / oneWeek);
        },
        calculateStartEndDates(weekString) {
            const [week] = weekString.match(/\d+/);
            const year = new Date().getFullYear();
            const firstDayOfYear = new Date(year, 0, 1);
            const dayOfWeek = firstDayOfYear.getDay();
            const startDate = new Date(firstDayOfYear.getTime() - (dayOfWeek - 1) * 86400000);
            const startOfWeek = new Date(startDate.getTime() + (week - 1) * 7 * 86400000);
            const endOfWeek = new Date(startOfWeek.getTime() + 6 * 86400000);
            this.listQuery.fromDate = moment(startOfWeek).format('YYYY-MM-DD');
            this.listQuery.toDate = moment(endOfWeek).format('YYYY-MM-DD');
        },
        formatTime(time) {
            return moment(time, "YYYY-MM-DD HH:mm").format("HH:mm");
        },
        async getWorkScheduleService() {
            const loading = this.$loading.show()
          try {
              const response = await WorkScheduleService.getListWorkScheduleApi(this.listQuery)
              this.listSchedule = response.data.data
              this.headers = response.data.header
              loading.hide()
          }  catch (e) {
              console.log(e)
              loading.hide()
          }
        },
        async getListShift() {
            try {
                const response = await WorkScheduleService.getListShiftApi()
                this.listShiftName = response.data.data
            }  catch (e) {
                console.log(e)
            }
        },
        async getListUsers() {
            try {
                const response = await WorkScheduleService.getUserCalendarApi()
                this.listUsers = response.data
            }  catch (e) {
                console.log(e)
            }
        },
        // openSearch() {
        //     this.isDialogSearch = true
        // },
        handleSearch() {
            this.getWorkScheduleService()
            this.isDialogSearch = false
        },
        checkStyleStudy(study) {
            if(study.registerAt) {
                if(study.status && study.status.id) {
                    switch (study.status.id) {
                        case constant.NOT_OPEN_CA:
                            return 'background-color: rgba(255, 109, 109, 1);color: white !important;'
                        case constant.SHIFT_CLOSED:
                            return 'background-color: #5CB560;color: white !important;'
                        case constant.AUTOMATIC_SHIFT_LOCKING:
                            return 'background-color: #5CB560;color: white !important;'
                    }
                }else {
                        return 'background-color: rgba(0, 139, 6, 1);color: white !important;  cursor: pointer'
                }
            }else {
                return 'background-color: rgba(0, 139, 6, 0.15);color: rgba(197, 197, 197, 1); cursor: pointer'
            }
        },
        // isAfterCurrentTime(date) {
        //     if(date) {
        //         console.log(date)
        //         const currentTime = moment();
        //         return moment(date).isBefore(currentTime);
        //     }
        // },
        checkStatus(status) {
            if(status === 2 || status == 3) {
                return 'border-red'
            }else if(status == 1) {
                return 'border-green'
            }
        },
        async createShift(user,time, shift) {
            if (this.checkPermission('bitu-schedule-management_create')) {
              try{
                const body = {
                  "userId": Number(user.id),
                  "shiftId": shift.shiftId,
                  "date": time.date
                }
                const response = await WorkScheduleService.createShiftApi(body)
                if(response.data.length === 0) {
                  this.$swal({
                    title: response.message,
                    icon: "error",
                    showConfirmButton: false,
                    timer: 2500,
                    toast: true,
                    position: 'top-right',
                  });
                }else {
                  this.$set(shift, 'registerAt', response.data.registerAt);
                  this.$set(shift, '_id', response.data._id);
                  this.$swal({
                    title: "Đăng ký lịch làm việc thành công.",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 2500,
                    toast: true,
                    position: 'top-right',
                  });
                }
              }catch (e) {
                console.log(e)
              }
            }
        },
        async deleteShift(study) {
            if (this.checkPermission('bitu-schedule-management_delete')) {
              try{
                const response = await WorkScheduleService.deleteShiftApi(study._id)
                if(response.data.length === 0) {
                  this.$swal({
                    title: response.message,
                    icon: "error",
                    showConfirmButton: false,
                    timer: 2500,
                    toast: true,
                    position: 'top-right',
                  });
                }else {
                  this.$delete(study, 'registerAt');
                  this.$swal({
                    title:  "Hủy đăng ký lịch làm việc thành công.",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 2500,
                    toast: true,
                    position: 'top-right',
                  });
                }
              }catch (e) {
                console.log(e)
              }
            }
        }
    },
}
</script>
<style>
</style>
<style lang="scss">
.work-schedule-management {
  &__table {
    margin-top: 15px;
  }

  thead th {
    position: sticky;
    top: 0px;
    background-color: #fff;
    border: 1px solid #D2D2D2;
    z-index: 100;
    height: 60px !important;
  }

  table {
    background: #fff;
    border-collapse: collapse;
  }

  .table-child {
    tbody tr {
      td {
        //border-bottom: 1px solid #D2D2D2;
        height: 70px;
        font-size: 14px !important;
          vertical-align: top;
      }

      td:first-child {
        border-bottom: none !important;
        border-left: none !important;
        border-right: none !important;
      }
    }
  }

  .table-parent {
      //table-layout: fixed !important;
    tbody tr {
      td {
          width: calc(100% / 7);
        border: 1px solid #D2D2D2;
        height: 70px;
        font-size: 14px;
      }
    }

    thead th {
      border: 1px solid #D2D2D2;
      height: 50px !important;
      font-size: 14px !important;
    }
  }
    .bg-not-open-ca {
            background: #FF6D6D;
    }
    .bg-automatic-shift-locking {
        background: #008B06;
    }
    .bg-shift-closed {
        background: #5CB560;
    }
    .border-green {
        background-color: white !important;
        border-radius: 20px;
        border: 1px solid #1E8015;
        color: #1E8015;
        font-size: 12px !important;
        padding: 3px 5px;
        font-weight: 500;
    }
    .border-red {
        background-color: white !important;
        border-radius: 20px;
        border: 1px solid #FF0F00;
        color: #FF0F00;
        font-size: 12px !important;
        padding: 3px 5px;
        font-weight: 500;
    }
}
.date-picker-week {
    input {
        color: rgba(0, 0, 0, 0.87);
        font-size: 16px !important;
        height: 40px !important;
        border: none;
        box-shadow: none;
        border-bottom: 1px solid #949494 !important;
        border-radius: 0 !important;
    }
}
.cursor-pointer {
    cursor: pointer !important;
}
.card-work-schedule {
}

</style>