<template>
  <v-app id="inspire">
    <!-- <SystemBar /> -->

    <AppBar />
    
    <SideBar />

    <LabelManagement />
  </v-app>
</template>

<script>
import AppBar from "../baseComponents/AppBar.vue";
import SideBar from "../components/SettingChat/SideBar.vue";
import LabelManagement from "../components/SettingChat/LabelManagement.vue";
// import LabelManagement from "../components/SettingChat/LabelManagement.vue"
// import OtherSettings from "../components/SettingChat/OtherSettings.vue"

export default {
  name: "chat",
  data: () => ({}),
  components: {
    AppBar,
    SideBar,
    LabelManagement,
    // LabelManagement,
    // OtherSettings
  },
};
</script>
<style lang="scss" scoped>
#inspire {
  background: #f9fafc;
  .customer {
    background: #ffffff;
    padding: 24px;
    margin: 30px;
    margin-top: 96px;
    border-radius: 10px;
  }
}
</style>
